window.sunburst = function(dom_id, sunburst, data) {
  let [labels, parents, values, colorway] = [sunburst.labels, sunburst.parents, sunburst.values, sunburst.colorway]

  //let ids = data.ids;
  let filename = data.filename;
  let domain = data.domain;
  let cuvee = data.cuvee;
  let source = data.source;

  let date = new Date().toISOString();
  date = `${date.slice(0,10).replaceAll('-', '_')}_${date.slice(11,19).replaceAll(':', '_')}`;
  filename  = `${filename}_${date}`;

  //ids = (ids === 'true')

  //let value_key = ids ? 'ids' : 'values';

  var data = [
    {
      type: 'sunburst',
      labels: labels,
      parents: parents,
      values: values,
      leaf: {"opacity": 1},
      marker: {
        line: {
          width: data.linewidth,
          color: '#fff'
        },
        colors: colorway
      },
      'branchvalues': 'total',
      hovertemplate: '<b>Arôme: </b> %{label}<br><br>\
                      <i> Pourcentage de \'%{parent}\': </i> %{percentParent:.0%}<br>\
                      <i> Pourcentage des arômes: </i> %{percentRoot:.0%}<br>\
                      <extra></extra>'
    },
  ];

  var layout = $(document).width() < 600 ? {
    margin: {l: 0, r: 0, t: 75, b: 0},
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    font: {
      size: 16
    },
    autosize: true,
    images: [
    ],
    annotations: [{
      xref: 'paper',
      yref: 'paper',
      yanchor: 'bottom',
      x: 1,
      y: 0,
      text: '© Winespace',
      showarrow: false,
      font: {
        size: 12,
        family: 'Raleway',
        color: '#707070'
      },
    },
    {
      xref: 'paper',
      yref: 'paper',
      yanchor: 'top',
      x: 0.5,
      y: 1.1,
      text: `${domain} <br> <b> ${cuvee} </b>`,
      font: {
        size: 12,
        family: 'Raleway',
        color: '#707070'
      },
      showarrow: false
    }]
  } : {
    margin: {l: 0, r: 0, t: 90, b: 0},
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    font: {
      size: 18
    },
    height: 800,
    images: [
    ],
    annotations: [{
      xref: 'paper',
      yref: 'paper',
      yanchor: 'bottom',
      x: 1,
      y: 0,
      text: '© Winespace',
      font: {
        size: 12,
        family: 'Raleway',
        color: '#707070'
      },
      showarrow: false
    },
    {
      xref: 'paper',
      yref: 'paper',
      yanchor: 'top',
      x: 0.5,
      y: 1.1,
      text: `${domain} <br> <b> ${cuvee} </b>`,
      font: {
        size: 14,
        family: 'Raleway',
        color: '#707070'
      },
      showarrow: false
    }]
  }

  var config = {
    // toImageButtonOptions: {
    //   format: 'png',
    //   width: 1000,
    //   height: 800,
    //   filename: filename
    // },
    displayModeBar: false
  }

  Plotly.newPlot(dom_id,
                 data,
                 layout,
                 config);
}

window.relayout = function (id) {
  Plotly.relayout(id, {
    autosize: true
  });
}